import './styles.css'
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import LandingPage from './landing-page/Page'
import TouchNGoPage from './campaign/touch-n-go/Page'
import AdminPage from './admin/Page'
import Promotions from './promotions/Promotions'
import UserPage from './user/Page'

const queryClient = new QueryClient()

const campaignTheme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Emprint',
    h1: {
      fontSize: '30px',
      fontWeight: '500',
      fontFamily: 'Emprint Bold',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: '300',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '200',
    },
    h4: {
      fontSize: '14px',
      fontWeight: '200',
      color: '#767676',
    },
    h5: {
      fontSize: '18px',
      fontWeight: '300',
      color: '#5A5A5A',
    },
    subtitle1: {
      fontSize: '22px',
      fontWeight: '300',
      fontFamily: "'Corsica MX Medium Cond',sans-serif",
    },
    subtitle2: {
      fontSize: '8px',
      fontWeight: '500',
      fontFamily: "'Corsica MX Medium Cond',sans-serif",
    },
    button: {
      fontWeight: '1000',
    },
  },
  components: {
    MuiStepLabel: { styleOverrides: { label: { fontSize: '17px' } } },
    MuiTypography: { styleOverrides: { caption: { fontSize: '15px' } } },
  },
})

function App() {
  return (
    <SnackbarProvider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={campaignTheme}>
            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path='/campaign/*' element={<TouchNGoPage />} />
              <Route path='/user/*' element={<UserPage />} />
              <Route path='/admin/*' element={<AdminPage />} />
              <Route path='/promotions' element={<Promotions />} />
              <Route path='/*' element={<LandingPage />} />
            </Routes>
          </ThemeProvider>
        </QueryClientProvider>
      </Router>
    </SnackbarProvider>
  )
}

export default App
