import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useNavigate, Navigate } from 'react-router-dom'
import { useLogin, useMe } from '../../api/user'
import { REDIRECT_AUTHENTICATED_PATH } from './RedirectPath'
import { CenteredContainer } from './CenteredContainer'

const SignIn = () => {
  const { data, refetch } = useMe()
  const loginApi = useLogin()
  const navigate = useNavigate()

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
  })

  const { enqueueSnackbar } = useSnackbar()
  const onSubmit = async (data) => {
    const res = await loginApi.mutateAsync(data).catch((e) => {
      enqueueSnackbar(e?.response?.data?.message ?? 'Failed to login', {
        variant: 'error',
      })
    })

    if (res?.status === 200) {
      enqueueSnackbar('Login Success', {
        variant: 'success',
      })
      await refetch()

      navigate(REDIRECT_AUTHENTICATED_PATH)
    }
  }

  if (data) return <Navigate to={REDIRECT_AUTHENTICATED_PATH} />

  return (
    <CenteredContainer>
      <Avatar sx={{ bgcolor: 'black' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Sign In
      </Typography>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <Controller
          name='phone'
          control={control}
          defaultValue=''
          rules={{ required: 'Phone number is required' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              margin='normal'
              label='Phone number'
              placeholder='60xxxxxxxxx'
              variant='outlined'
              autoComplete='off'
              value={value}
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          defaultValue=''
          rules={{
            required: 'Password is required',
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              margin='normal'
              label='Password'
              variant='outlined'
              type='password'
              autoComplete='off'
              value={value}
              fullWidth
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Link href='/user/forgot-password' variant='body2'>
              Forgot Password?
            </Link>
          </Grid>
          <Grid item>
            <Link href='/user/sign-up' variant='body2'>
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </Box>
    </CenteredContainer>
  )
}

export default SignIn
