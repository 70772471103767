import React from 'react'
import { Navigate } from 'react-router-dom'
import { useMe } from '../../api/user'
import { REDIRECT_AUTHENTICATED_PATH } from './RedirectPath'

const AdminRedirectRoute = () => {
  const { data, isLoading } = useMe()

  if (isLoading) return <></>

  if (!data) return <Navigate to='/admin/login' />

  return <Navigate to={REDIRECT_AUTHENTICATED_PATH} />
}

export default AdminRedirectRoute
