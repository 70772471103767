import React from 'react'
import { Box } from '@mui/material'

export const CenteredContainer = ({ children, ...props }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      maxWidth={500}
      m='auto'
      mt={15}
      p={5}
      {...props}
    >
      {children}
    </Box>
  )
}
