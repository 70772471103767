import React, { useState } from 'react'
import { AppBar, Toolbar, Box, Button, Menu, ListItemIcon, MenuItem, Divider } from '@mui/material'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { useLogout } from '../api/user'

const StyledButton = styled(Button)`
  && {
    color: #000;
    :hover {
      color: #f3b862;
    }
  }
`

const Navbar = ({ user }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const { mutateAsync } = useLogout()

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleLogout = async () => {
    await mutateAsync()
    const redirectPath = user?.role === 'admin' ? '/admin/login' : '/user/sign-in'
    navigate(redirectPath)
  }

  const adminMenuItems = [{ label: 'TNG Campaign', to: '/admin/panel' }]

  const userMenuItems = []

  const renderMenu = (menuItems) => (
    <Menu
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {menuItems.length > 0 &&
        menuItems.map((item, index) => (
          <Box key={item.to}>
            <MenuItem component={Link} to={item.to}>
              {item.label}
            </MenuItem>
            <Divider />
          </Box>
        ))}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )

  return (
    <AppBar sx={{ background: 'white' }}>
      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box display='flex' alignItems='center'>
            <Link
              to={user ? (user.role === 'admin' ? '/admin' : '/') : '/'}
              style={{ textDecoration: 'none', color: '#000' }}
            >
              <Box pr={1}>
                <img src='/assets/HTEcoBatteryLogo.png' height={40} alt='logo' />
              </Box>
            </Link>
          </Box>
          {user && (
            <StyledButton onClick={handleMenuClick} style={{ textDecoration: 'underline' }}>
              {user.name}
            </StyledButton>
          )}
        </Box>
        {user?.role === 'admin' ? renderMenu(adminMenuItems) : renderMenu(userMenuItems)}
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
