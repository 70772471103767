import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Container, Grid } from '@mui/material'
import Navbar from '../shared/Navbar'
import { tngBanner, incentiveBanner } from '../constant/image'

const promotions = [
  { img: tngBanner, link: '/campaign', text: 'Starting from 3rd February 2025' },
  { img: incentiveBanner, link: '/user', text: 'Period: May 25 - Sep 25' },
]

const Promotions = () => (
  <Container component='main'>
    <Navbar />
    <Box textAlign='center' mt={15} mb={8}>
      <Typography
        sx={{ fontSize: { xs: '30px', md: '50px' }, fontFamily: 'Corsica LX Bold', lineHeight: 1 }}
      >
        Promotions
      </Typography>
    </Box>
    <Grid container spacing={4} mb={8}>
      {promotions.map(({ img, link, text }, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={img} width='100%' alt='Promotion' />
            <Typography variant='h4'>{text}</Typography>
          </Link>
        </Grid>
      ))}
    </Grid>
  </Container>
)

export default Promotions
