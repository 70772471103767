import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { TextField, Button, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useResetPassword } from '../../api/user'
import { CenteredContainer } from './CenteredContainer'

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { mutateAsync } = useResetPassword()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: '',
      verifyNewPassword: '',
    },
  })

  const onSubmit = async (data) => {
    const res = await mutateAsync(data).catch((e) => {
      enqueueSnackbar(e?.response?.data?.message ?? 'Error resetting password', {
        variant: 'error',
      })
    })

    if (res?.status === 200) enqueueSnackbar('Password has been reset.', { variant: 'success' })

    navigate('/user')
  }

  const newPassword = watch('newPassword')

  return (
    <CenteredContainer>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='newPassword'
          control={control}
          rules={{
            required: 'New Password is required',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='password'
              label='New Password'
              fullWidth
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              margin='normal'
            />
          )}
        />
        <Controller
          name='verifyNewPassword'
          control={control}
          rules={{
            required: 'Please verify your password',
            validate: (value) => value === newPassword || 'Passwords must match',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='password'
              label='Verify New Password'
              fullWidth
              error={!!errors.verifyNewPassword}
              helperText={errors.verifyNewPassword?.message}
              margin='normal'
            />
          )}
        />
        <Button
          type='submit'
          variant='contained'
          fullWidth
          sx={{ mt: 2 }}
          style={{ backgroundColor: 'black', color: 'white' }}
        >
          Confirm
        </Button>
      </Box>
    </CenteredContainer>
  )
}

export default ResetPassword
