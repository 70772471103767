import React from 'react'
import { Navigate } from 'react-router-dom'
import { useMe } from '../../api/user'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import { Box } from '@mui/material'
import Navbar from '../../shared/Navbar'

const UserPrivateRoute = () => {
  const { data, isLoading } = useMe({})

  if (isLoading) return <></>

  if (!data) return <Navigate to='/user/sign-in' />

  if (data.role === 'admin') return <Navigate to='/admin' />

  return (
    <Container component='main'>
      <Navbar user={data} />
      <Box marginTop={15}>
        <Outlet />
      </Box>
    </Container>
  )
}

export default UserPrivateRoute
