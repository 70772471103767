import React, { useState } from 'react'
import {
  Box,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CircularProgress,
  Autocomplete,
  Dialog,
  DialogContent,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'
import axios from 'axios'
import { usePostApplication, useAllowSubmission } from '../../api/application'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import UploadIcon from '@mui/icons-material/Upload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { START_DATE, END_DATE } from '../../constant/limit'
import { state } from '../../constant/state'
import { product } from '../../constant/product'

const StyledFieldBox = styled(Box)`
  && {
    max-width: 800px;
    justify-content: space-evenly;
    min-height: 80px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`

const ApplicationForm = () => {
  const { register, handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  })
  const { mutateAsync, isLoading } = usePostApplication()

  const { data } = useAllowSubmission()

  const { enqueueSnackbar } = useSnackbar()

  const [uploadError, setUploadError] = useState('')
  const [batteryUploadError, setBatteryUploadError] = useState('')

  const [submitIsLoading, setSubmitIsLoading] = useState(false)
  const [openDialog, setopenDialog] = useState(false)

  const today = new Date().toISOString().slice(0, 10)

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    setSubmitIsLoading(true)
    const invoice = data?.invoice?.[0]
    const batteryPhoto = data?.batteryPhoto?.[0]

    const formData = new FormData()

    formData.append('invoice', invoice, invoice?.name ?? '')
    formData.append('batteryPhoto', batteryPhoto, batteryPhoto?.name ?? '')

    const uploadRes = await axios({
      url: `/api/upload`,
      method: 'POST',
      data: formData,
    })

    const res = await mutateAsync({
      ...data,
      batteryInvoice: uploadRes?.data?.batteryInvoice?.[0],
      batteryPhoto: uploadRes?.data?.batteryPhoto?.[0],
    }).catch((err) => {
      enqueueSnackbar(err?.response?.data?.errors[0].msg ?? 'Submit fail', {
        variant: 'error',
      })
    })

    if (res?.status === 200) {
      setopenDialog(true)
      setTimeout(() => {
        navigate('/campaign')
      }, 3000)
    }
    setSubmitIsLoading(false)

    return res
  }

  const handleClose = () => {
    setopenDialog(false)
  }

  return (
    <Box sx={{ m: { xs: 5, sm: 10 } }}>
      <Box display='flex' justifyContent='flex-end'>
        <Button component='a' href='/campaign' variant='outlined' size='small'>
          Back
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFieldBox>
          <Controller
            name='name'
            control={control}
            defaultValue=''
            rules={{
              required: 'Name is required',
              maxLength: { value: 35, message: 'Exceed word count' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Name'
                fullWidth
                variant='standard'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='phone'
            control={control}
            defaultValue=''
            rules={{
              required: 'Phone number is required',
              pattern: {
                value: /^(601)[0-46-9]-*[0-9]{7,8}$/,
                message: `Invalid phone number. Phone number should follow the format of 601xxxxxxxx. '+' sign is not required.`,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Phone number'
                variant='standard'
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                placeholder='60127123456'
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='email'
            control={control}
            defaultValue=''
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
              maxLength: { value: 50, message: 'Exceed word count' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Email (Optional)'
                variant='standard'
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='carBrand'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value } }) => (
              <TextField
                label='Car Brand (eg.Toyota)'
                variant='standard'
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='carModel'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value } }) => (
              <TextField
                label='Car Model (eg.Vios)'
                variant='standard'
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='state'
            control={control}
            rules={{
              required: 'State is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={state}
                onChange={(e, data) => onChange(data)}
                value={value || ''}
                renderInput={(params) => (
                  <TextField
                    variant='standard'
                    error={!!error}
                    fullWidth
                    helperText={error ? error.message : null}
                    {...params}
                    label='State'
                  />
                )}
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='workshop'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value } }) => (
              <TextField
                label='Workshop Name'
                fullWidth
                variant='standard'
                value={value}
                onChange={onChange}
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='brand'
            control={control}
            rules={{
              required: 'Brand is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={product}
                onChange={(e, data) => onChange(data)}
                value={value || ''}
                renderInput={(params) => (
                  <TextField
                    variant='standard'
                    error={!!error}
                    fullWidth
                    helperText={error ? error.message : null}
                    {...params}
                    label='Brand'
                  />
                )}
              />
            )}
          />
        </StyledFieldBox>
        <StyledFieldBox>
          <Controller
            name='serialNumber'
            control={control}
            defaultValue=''
            rules={{
              required: 'Serial number is required',
              pattern: {
                value: /^V.*/,
                message: 'Invalid serial number format',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const handleChange = (event) => {
                const sanitizedValue = event.target.value.replace(/\s/g, '').toUpperCase()
                onChange(sanitizedValue)
              }
              return (
                <TextField
                  label='Serial Number'
                  variant='standard'
                  value={value}
                  onChange={handleChange}
                  error={!!error}
                  fullWidth
                  helperText={error ? error.message : null}
                />
              )
            }}
          />
        </StyledFieldBox>
        <StyledFieldBox display='flex' flexDirection='column'>
          <Box>
            <Controller
              name='invoice'
              control={control}
              defaultValue=''
              rules={{
                required: 'Required',
              }}
              render={({ field: { value }, fieldState: { error } }) => {
                const props = register('invoice')
                const combinedOnchange = (e) => {
                  const fileSize = e?.target?.files?.[0]?.size
                  const fileMb = fileSize / 1024 ** 2
                  if (fileMb > 5) {
                    setUploadError('File size must be within 5MB.')
                    setValue('invoice', null)
                  } else {
                    props.onChange(e)
                    setUploadError('')
                  }
                }
                return (
                  <>
                    <Button variant='outlined' startIcon={<UploadIcon />} component='label'>
                      Upload Receipt/Invoice
                      <input
                        type='file'
                        accept='image/*'
                        hidden
                        {...props}
                        onChange={combinedOnchange}
                      />
                    </Button>
                    {error ? <FormHelperText error={true}>{error?.message}</FormHelperText> : null}
                    {value ? <FormHelperText>{value[0]?.name}</FormHelperText> : null}
                    {uploadError ? (
                      <FormHelperText error={true}>{uploadError}</FormHelperText>
                    ) : null}
                  </>
                )
              }}
            />
          </Box>
        </StyledFieldBox>
        <StyledFieldBox display='flex' flexDirection='column'>
          <Box>
            <Controller
              name='batteryPhoto'
              control={control}
              defaultValue=''
              rules={{
                required: 'Required',
              }}
              render={({ field: { value }, fieldState: { error } }) => {
                const props = register('batteryPhoto')
                const combinedOnchange = (e) => {
                  const fileSize = e?.target?.files?.[0]?.size
                  const fileMb = fileSize / 1024 ** 2
                  if (fileMb > 5) {
                    setBatteryUploadError('File size must be within 5MB.')
                    setValue('batteryPhoto', null)
                  } else {
                    props.onChange(e)
                    setBatteryUploadError('')
                  }
                }
                return (
                  <>
                    <Button variant='outlined' startIcon={<UploadIcon />} component='label'>
                      Upload a photo of the serial number on the battery
                      <input
                        type='file'
                        accept='image/*'
                        hidden
                        {...props}
                        onChange={combinedOnchange}
                      />
                    </Button>
                    {error ? <FormHelperText error={true}>{error?.message}</FormHelperText> : null}
                    {value ? <FormHelperText>{value[0]?.name}</FormHelperText> : null}
                    {batteryUploadError ? (
                      <FormHelperText error={true}>{batteryUploadError}</FormHelperText>
                    ) : null}
                  </>
                )
              }}
            />
          </Box>
        </StyledFieldBox>
        <StyledFieldBox>
          <Box my={2}>
            <Typography variant='h4'>
              *Please make sure your phone number is the same as your Touch'n Go e-Wallet account.
            </Typography>
            <Typography variant='h4'>
              *Redemption of one (1) Touch'N Go reload pin number is strictly limited to one (1)
              contact number.
            </Typography>
            <Typography variant='h4'>
              *Purchase a VARTA® AGM automotive battery from HT Eco Battery Sdn Bhd's participating
              authorised dealers during the campaign period starting from 3rd February 2025.
            </Typography>
            <Typography variant='h4'>
              *The HT battery serial number must match in the HT Eco Battery Sdn Bhd system. If the
              provided serial number is not found in the system, it will be considered ineligible
              for the redemption of the Touch'N Go reload pin number.
            </Typography>
            <Typography variant='h4'>
              *By submitting this form, you confirm that you have read, understood, and will respect
              the{' '}
              <a
                style={{ color: '#002984' }}
                href={'/assets/TermsAndConditions.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </a>{' '}
              &{' '}
              <a
                style={{ color: '#002984' }}
                href={'/assets/PrivacyPolicy.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy.
              </a>
            </Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              type='submit'
              variant='contained'
              disabled={
                isLoading ||
                submitIsLoading ||
                today < START_DATE ||
                today >= END_DATE ||
                !data?.allow
              }
              endIcon={
                (isLoading || submitIsLoading) && (
                  <CircularProgress style={{ color: '#fff' }} size='1rem' />
                )
              }
            >
              Submit
            </Button>
            <Typography variant='body2' color='error' mt={1}>
              {(() => {
                if (!data?.allow) return 'Campaign has been fully redeemed.'
                else if (today < START_DATE) return 'Campaign has not started yet.'
                else if (today >= END_DATE) return 'Campaign has ended.'
                else if (isLoading || submitIsLoading)
                  return 'Please wait while we process your submission.'
                else return null
              })()}
            </Typography>
          </Box>
        </StyledFieldBox>
      </form>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent dividers>
          <Box display='flex' justifyContent='flex-start' alignItems='center' pb={3}>
            <CheckCircleIcon style={{ color: '#4caf50' }} />
            <Typography variant={'h3'}>{'Application sent.'}</Typography>
          </Box>
          <Typography gutterBottom>Redirecting back to homepage...</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ApplicationForm
