import React from 'react'
import { Box, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export const AccessDenied = () => {
  return (
    <Box textAlign='center' justifyContent='center' width='100%'>
      <ErrorOutlineIcon style={{ fontSize: 200, color: 'red' }} />
      <Typography align='center' fontSize={30}>
        Access denied
      </Typography>
      <Typography align='center' fontSize={20}>
        You have no access to this page
      </Typography>
    </Box>
  )
}
