import { Outlet } from 'react-router-dom'
import Navbar from '../shared/Navbar'
import { Box } from '@mui/material'

const Header = ({ banner }) => {
  return (
    <Box>
      <Navbar />
      {banner && (
        <Box display='flex' justifyContent='center' marginTop={7}>
          <img src={banner} width='100%' alt={'banner'} />
        </Box>
      )}
      <Outlet />
    </Box>
  )
}

export default Header
