import React from 'react'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  MenuItem,
  CircularProgress,
} from '@mui/material'

import { useSnackbar } from 'notistack'

const subject = ['Inquiry for Products & Services', 'Others']

export default function Contact() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm()

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data) => {
    const res = await fetch('/api/email', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (res?.status === 200) {
      enqueueSnackbar('Enquiry sent', {
        variant: 'success',
      })
      reset()
    } else {
      enqueueSnackbar('Failed to send enquiry', {
        variant: 'error',
      })
    }
    return res
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id='contactus'>
      <>
        <Paper>
          <Box px={3} py={2} pt={7}>
            <Box mb={3}>
              <Typography variant='h1' style={{ fontSize: '28px' }}>
                Get in contact with us
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='name'
                  label='Name'
                  size='small'
                  {...register('name', {
                    required: 'Required',
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='companyName'
                  label='Company Name'
                  size='small'
                  {...register('companyName', {
                    maxLength: {
                      value: 120,
                      message: 'Maximum 120 characters',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='email'
                  label='Email'
                  size='small'
                  {...register('email', {
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='phone'
                  label='Phone'
                  size='small'
                  placeholder='Eg. 60123456789'
                  {...register('phone', { required: 'Required' })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  {...register('subject', { required: true })}
                  select
                  onChange={(e) => setValue('subject', e.target.value, true)}
                  label='Subject'
                  defaultValue={'Inquiry for Products & Services'}
                >
                  {subject.map((value, idx) => (
                    <MenuItem value={value} key={idx}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  name='description'
                  label='Comments / Questions'
                  {...register('description', {
                    required: 'Required',
                    maxLength: {
                      value: 500,
                      message: 'Maximum 500 characters',
                    },
                  })}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                />
              </Grid>
            </Grid>
            <Box mt={3} display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                style={{ padding: '12px 32px' }}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && <CircularProgress size='1.5rem' style={{ color: 'white' }} />
                }
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </>
    </form>
  )
}
