import { useQuery, useMutation } from 'react-query'
import axios from 'axios'

const ENDPOINT = '/api/user'
const QUERY_KEY = 'user'

export const useMe = (config = {}) =>
  useQuery(
    [QUERY_KEY, 'detail', 'me'],
    async () => (await axios.get(`${ENDPOINT}/me`)).data,
    config
  )

export const useLogin = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/login`, data), config)

export const useLogout = (config = {}) =>
  useMutation(() => axios.post(`${ENDPOINT}/logout`), config)

export const useOTP = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/otp`, data), config)

export const useVerifyOTP = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/verify-otp`, data), config)

export const useResetPassword = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/reset-password`, data), config)
